import React, { useMemo, useRef, useState } from 'react'
import { Select, SelectProps, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { UsersApi } from '../../modules/api'
import { User } from '../../types'

type UserSelectProps = {
  onChange?: SelectProps['onChange']
}

export const UserSelect: React.FC<UserSelectProps> = ({ onChange }) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<User[]>([])
  const { t } = useTranslation()
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      UsersApi.getUsers({ searchTerm: value, page: 1, role: 'client' }).then(
        (usersResponse) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }

          setOptions(usersResponse.users)
          setFetching(false)
        }
      )
    }

    return debounce(loadOptions, 800)
  }, [])

  return (
    <Select
      labelInValue
      onChange={onChange}
      placeholder={t('users.selectUsers')}
      filterOption={false}
      onSearch={debounceFetcher}
      showSearch
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options.map((user) => ({
        label: `${user.name}(${user.email})`,
        value: user.id,
      }))}
      style={{ width: '330px' }}
      dropdownMatchSelectWidth={false}
    />
  )
}
