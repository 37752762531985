import React from 'react'
import { Layout, Button, Typography } from 'antd'
import styled from 'styled-components'

import { MainMenu } from './MainMenu'
import { useAuth } from '../../modules'
import logo from '../../assets/logo.png'

const { Header: AntHeader } = Layout

const Header = styled(AntHeader)`
  position: sticky;
  display: flex;
  top: 0;
  zindex: 1;
  width: 100%;
`

const HeaderSearchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const AuthenticatedHeader: React.FC = () => {
  const { handleLogout, user } = useAuth()
  return (
    <Header style={{ background: 'white' }}>
      <img src={logo} alt="GymAdmin" style={{ maxHeight: '64px' }} />
      <MainMenu />
      <HeaderSearchWrap>
        <Typography.Text>{`${user?.name}(${user?.email})`}</Typography.Text>
        <Button onClick={handleLogout}>Logout</Button>
      </HeaderSearchWrap>
    </Header>
  )
}
