import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Avatar,
  Button,
  Popconfirm,
  Table,
  TableColumnType,
  TablePaginationConfig,
  Tag,
  notification,
} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { User } from '../../types'
import { getRoleName } from '../../helpers'
import { useAuth } from '../auth'
import { UsersApi } from '../api'
import { UserMembershipStatus } from '../userMemberships'
import { useTranslation } from 'react-i18next'

type UsersTableProps = {
  users: User[]
  showRole?: boolean
  showActions?: boolean
  hideStatus?: boolean
  hideMembership?: boolean
  onEditUser?: (user: User) => void
  onDeleteUser?: () => void
  loading?: boolean
  total?: number
  onChange: (page: number) => void
}

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  onEditUser,
  showRole = false,
  showActions = false,
  onDeleteUser,
  loading,
  hideStatus,
  hideMembership,
  total,
  onChange,
}) => {
  const { isSuperAdmin } = useAuth()
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: total || 0,
  })

  const columns: TableColumnType<User>[] = [
    {
      title: t('users.avatar'),
      dataIndex: 'avatarUrl',
      key: 'avatar',
      render: (value, record) =>
        value ? (
          <img src={value} alt={record.email} style={{ maxHeight: '64px' }} />
        ) : (
          <Avatar>
            {record.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </Avatar>
        ),
    },
    {
      title: t('users.name'),
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <Link to={`/users/${record.id}`}>{value}</Link>
      ),
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('memberships.status'),
      dataIndex: ['userMembership'],
      key: 'status',
      render: (value) => (
        <UserMembershipStatus status={value?.status} endDate={value?.endDate} />
      ),
    },
    {
      title: t('memberships.membership'),
      dataIndex: ['userMembership', 'membership', 'name'],
      key: 'membership',
    },
    {
      title: t('users.dob'),
      dataIndex: 'dob',
      key: 'dob',
      render: (value) => (value ? moment(value).format('DD.MM.YYYY.') : null),
    },
  ]

  if (hideMembership) {
    const membershipIndex = columns.findIndex((c) => c.key === 'membership')
    delete columns[membershipIndex]
  }

  if (hideStatus) {
    const statusIndex = columns.findIndex((c) => c.key === 'status')
    delete columns[statusIndex]
  }

  if (showRole) {
    columns.push({
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (value) => <Tag>{getRoleName(value)}</Tag>,
    })
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination)
    onChange(pagination.current || 1)
  }

  const handleDeleteUser = async (user: User) => {
    try {
      await UsersApi.destroy(user.id)
      notification.open({
        message: t('general.deleteSuccess'),
        type: 'success',
      })
      onDeleteUser?.()
    } catch (error) {
      console.error(error)
      notification.open({
        message: t('errors.deleteError'),
        type: 'error',
      })
    }
  }

  const getEditButton = (
    record: User,
    onClick: UsersTableProps['onEditUser']
  ) => (
    <Button key="edit" icon={<EditOutlined />} onClick={onClick as any}>
      Edit
    </Button>
  )

  if (showActions) {
    columns.push({
      key: 'actions',
      render: (_, record) => [
        onEditUser ? getEditButton(record, onEditUser) : null,
        onDeleteUser ? (
          <Popconfirm
            title={`${t('general.areYouSure')}`}
            onConfirm={() => handleDeleteUser(record)}
            okText={`${t('general.yes')}`}
            cancelText={`${t('general.no')}`}
          >
            <Button key="delete" danger icon={<DeleteOutlined />}>
              {t('actions.delete')}
            </Button>
          </Popconfirm>
        ) : null,
      ],
    })
  }

  useEffect(() => {
    setPagination({ ...pagination, total })
  }, [total])

  return (
    <Table
      dataSource={users}
      pagination={pagination}
      columns={columns}
      rowKey="id"
      loading={loading}
      onChange={handleTableChange}
    />
  )
}

export default UsersTable
